<template>
   <div>
      <div class="mt-3">
         <div class="container" id="myTabContent">
            <div class="modal-body" v-if="plan">
               <div class="row gutters">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label for="planName" class="required">{{ t('PANEL.PLAN.PLAN_NAME') }}: </label>
                        <input type="text" id="planName" name="planName" v-model="plan.name"
                               class="form-control">
                        <div v-if="invalidPlanName" class="validation">{{ t(invalidPlanName) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planEmailNotf">{{
                              t('PANEL.PLAN.EMAIL_NOTIFICATIOS')
                           }}: </label>
                        <input type="number" @keyup="validateNumberMax(plan)" id="planEmailNotf" name="planEmailNot"
                               class="form-control" v-model="plan.email_notifications">
                        <div v-if="invalidPlanEmail" class="validation">{{ t(invalidPlanEmail) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planMaxLots">{{ t('PANEL.PLAN.MAX_LOTS_PERMITED') }}: </label>
                        <input type="number" @keyup="validateNumberMax(plan)" id="planMaxLots" name="planMaxLots"
                               class="form-control" v-model="plan.max_lots_permitted">
                        <div v-if="invalidMaxLots" class="validation">{{ t(invalidMaxLots) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planWppNot">{{
                              t('PANEL.PLAN.WHATSAPP_NOTIFICATIONS')
                           }}: </label>
                        <input type="number" @keyup="validateNumberMax(plan)" id="planWppNot" name="planWppNot"
                               class="form-control" v-model="plan.whatsapp_notifications">
                        <div v-if="invalidPlanWpp" class="validation">{{ t(invalidPlanWpp) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label for="planWppNot">{{
                              t('PANEL.PLAN.MAX_SIZE_DRIVE')
                           }}: </label>
                        <input type="text" id="planWppNot" name="planWppNot"
                               class="form-control" v-model.lazy="plan.size_store_drive" v-money3="sizeDriveConfig">
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer container" style="justify-content: space-between">
            <div>
               <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div>
               <button class="btn btn-secondary mr-2" data-dismiss="modal" type="button">
                  {{ t('ACTIONS.CLOSE') }}
               </button>
               <button class="btn btn-primary" @click="validateTab()">
                  {{ t('ACTIONS.SAVE') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Plan from "../../services/Plan";
import Validate from "@/components/validate";
import {Money3Directive} from 'v-money3';

export default {
   name: "createPlanComponent",
   mixins: [Validate],
   directives: {money3: Money3Directive},
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   data() {
      return {
         plan: {},
         invalidPlanName: '',
         invalidPlanEmail: '',
         invalidPlanWpp: '',
         invalidMaxLots: '',
         invalidAmountMinBill: '',
         amountMaxBillConfig: {
            min: 0.00,
            prefix: 'R$ ',
            masked: true,
            decimal: ',',
            precision: 2,
            disabled: false,
            thousands: '.',
            allowBlank: false,
            disableNegative: true,
         },
         sizeDriveConfig: {
            min: 0.00,
            suffix: ' GB',
            masked: true,
            decimal: '.',
            precision: 2,
            disabled: false,
            thousands: '',
            allowBlank: false,
            disableNegative: true,
         },
         amountTaxConfig: {
            min: 0.00,
            prefix: 'R$ ',
            masked: true,
            decimal: ',',
            precision: 2,
            disabled: false,
            thousands: '.',
            allowBlank: false,
            disableNegative: true,
         },
      }
   },
   methods: {
      validateTab() {
         let valid = true;
         if (!this.plan.name) {
            this.invalidPlanName = 'PANEL.PLAN.FIELD_REQUIRED';
            valid = false;
         } else this.invalidPlanName = '';

         if (!this.plan.email_notifications) {
            this.invalidPlanEmail = 'PANEL.PLAN.FIELD_REQUIRED';
            valid = false;
         } else this.invalidPlanEmail = '';
         if (!this.plan.max_lots_permitted) {
            this.invalidMaxLots = 'PANEL.PLAN.FIELD_REQUIRED';
            valid = false;
         } else this.invalidMaxLots = '';
         if (!this.plan.whatsapp_notifications) {
            this.invalidPlanWpp = 'PANEL.PLAN.FIELD_REQUIRED';
            valid = false;
         } else this.invalidPlanWpp = '';

         if (valid && this.validateNumberMax(this.plan)) {
            this.create();
         }
      },
      validateNumberMax(iten) {
         let next = true;
         if (iten.email_notifications?.toString().length > 9) {
            this.invalidPlanEmail = 'PANEL.FIELD_MAX_EXECEDED';
            document.getElementById('planEmailNotf').classList.add('is-invalid');
            next = false;
         } else {
            this.invalidPlanEmail = ''
            document.getElementById('planEmailNotf').classList.remove('is-invalid');
         }
         if (iten.max_lots_permitted?.toString().length > 9) {
            this.invalidMaxLots = 'PANEL.FIELD_MAX_EXECEDED';
            document.getElementById('planMaxLots').classList.add('is-invalid');
            next = false;
         } else {
            this.invalidMaxLots = '';
            document.getElementById('planMaxLots').classList.remove('is-invalid');
         }
         if (iten.whatsapp_notifications?.toString().length > 9) {
            this.invalidPlanWpp = 'PANEL.FIELD_MAX_EXECEDED';
            document.getElementById('planWppNot').classList.add('is-invalid');
            next = false;
         } else {
            this.invalidPlanWpp = '';
            document.getElementById('planWppNot').classList.remove('is-invalid');
         }
         return next;
      },
      create() {
         this.$store.commit('changeLoading', true);
         this.plan.size_store_drive ? this.plan.size_store_drive = this.plan.size_store_drive.replace(' GB', '') : this.plan.size_store_drive = null;
         Plan.create(this.plan).then((resp) => {
            if (!resp.validations) {
               this.plan = {};
               document.getElementById('closeX').click();
               this.$emit('created');
            }
            this.$store.commit('changeLoading', false);
         }).catch((error) => {
            this.$store.commit('changeLoading', false);
            this.errorMsg(error);
         })
      }
   },
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type="number"] {
   -moz-appearance: textfield;
}
</style>
