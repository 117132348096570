<template>
   <div>
      <div class="mt-3">
         <div class="container" id="myTabContent">
            <div class="modal-body" v-if="plan">
               <div class="row gutters">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label for="planName" class="required">{{ t('PANEL.PLAN.PLAN_NAME') }}: </label>
                        <input type="text" id="planName" name="planName" v-model="plan.name"
                               class="form-control" disabled>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planEmailNotf">{{
                              t('PANEL.PLAN.EMAIL_NOTIFICATIOS')
                           }}: </label>
                        <input type="number" id="planEmailNotf" name="planEmailNot"
                               class="form-control" v-model="plan.email_notifications" disabled>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planMaxLots">{{ t('PANEL.PLAN.MAX_LOTS_PERMITED') }}: </label>
                        <input type="number" id="planMaxLots" name="planMaxLots"
                               class="form-control" v-model="plan.max_lots_permitted" disabled>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label class="required" for="planWppNot">{{
                              t('PANEL.PLAN.WHATSAPP_NOTIFICATIONS')
                           }}: </label>
                        <input type="number" id="planWppNot" name="planWppNot"
                               class="form-control" v-model="plan.whatsapp_notifications" disabled>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                     <div class="form-group">
                        <label for="plandrive">{{
                              t('PANEL.PLAN.MAX_SIZE_DRIVE')
                           }}: </label>
                        <input type="text" @keyup="validateNumberMax(plan)" id="plandrive" name="plandrive"
                               class="form-control" v-model.lazy="plan.size_store_drive" v-money3="sizeDriveConfig" disabled>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer container" style="justify-content: space-between">
            <div>
               <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div>
               <button class="btn btn-secondary mr-2" data-dismiss="modal" type="button">
                  {{ t('ACTIONS.CLOSE') }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {Money3Directive} from 'v-money3';

export default {
   name: "viewPlanComponent",
   directives: {money3: Money3Directive},
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   props: {
      planIndex: {
         type: Object
      },
   },
   watch: {
      planIndex: function () {
         this.$store.commit('changeLoading', true);
         this.plan = this.planIndex;
         this.$store.commit('changeLoading', false);
      },
   },
   data() {
      return {
         activeTab: 0,
         plan: {
            has_split: false
         },
         invalidPlanName: '',
         invalidPlanEmail: '',
         invalidPlanWpp: '',
         invalidMaxLots: '',
         invalidAmountMinBill: '',
         amountMaxBillConfig: {
            min: 0.00,
            prefix: 'R$ ',
            masked: true,
            decimal: ',',
            precision: 2,
            disabled: false,
            thousands: '.',
            allowBlank: false,
            disableNegative: true,
         },
         sizeDriveConfig: {
            min: 0.00,
            suffix: ' GB',
            masked: true,
            decimal: '.',
            precision: 2,
            disabled: false,
            thousands: '',
            allowBlank: false,
            disableNegative: true,
         },
      }
   },
   mounted() {
      this.plan = this.planIndex;
   },
   methods: {
   }
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type="number"] {
   -moz-appearance: textfield;
}
</style>
